<template>
    <div class="nav-tabs-custom">
         <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
               <a class="nav-link active" v-bind:id="'tab11'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_11_'+tabOption" type="button" role="tab" aria-controls="profile" aria-selected="false"><i class="fa fa-ellipsis-h"></i> <span>List Details</span></a>
            </li>
            <li class="nav-item" role="presentation">
               <a class="nav-link" v-bind:id="'tab21'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_21_'+tabOption" type="button" role="tab"><i class="fa fa-bell"></i><span>Subscribers</span></a>
            </li>
            <li class="nav-item" role="presentation">
               <a class="nav-link" v-bind:id="'tab31'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_31_'+tabOption" type="button" role="tab"><i class="fa fa-fw fa-bell-slash"></i> <span>Unsubscribes</span></a>
            </li>
         </ul>
         <div class="tab-content" id="myTabContent">
            <div class="tab-pane show active" v-bind:id="'tab_11_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">  
                <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                <label class="control-label text-left">Mailing List Name</label>
                                <input iservice-id="Name" type="text" class="form-control has-error">
                                <!--v-if-->
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                <label class="control-label text-left">Description</label>
                                <input iservice-id="Description" type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                <label class="col-12 control-label text-left">Shown In My Account</label>
                                <div>
                                    <div class="iservice-switch" iservice-id="Shown">
                                    <label class="switch">
                                        <input type="checkbox" iservice-switch="">
                                        <i></i>
                                    </label>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                    <label class="col-12 control-label text-left">Segments <span class="data-v-tooltip" data-v-tooltip="Display this list in the subscriptions section of the customer portal for contacts in the selected segments." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);">
                                        <i class="fa fa-question-circle text-info help-icon"></i>
                                        </span>
                                    </label>
                                    </div>
                                    <div class="form-group">
                                    <div class="col-md-12">
                                        <div class="checkbox col-md-3" iservice-id-prefix="'Segment'">
                                        <label>
                                            <input id="5" type="checkbox" class="form-check-input" value="5"> _iService </label>
                                        </div>
                                        <div class="checkbox col-md-3" iservice-id-prefix="'Segment'">
                                        <label>
                                            <input id="2" type="checkbox" class="form-check-input" value="2"> 1to1service </label>
                                        </div>
                                    
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                <div class="col-2">
                                    <button iservice-id="Save" class="btn btn-primary">Save</button>
                                </div>
                                <div class="col-10 admin-error">
                                    <!--v-if-->
                                </div>
                                </div>
                            </div>
                </div>
                       
                <div class="row">
                    <div class="col-md-12">
                        <div class="nested-panel-heading skill-heading">
                        <h3 class="nested-title">This List is used in the following Mass Mailings</h3>
                        </div>
                        <p>None</p>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" v-bind:id="'tab_21_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
                <SubscribersList></SubscribersList>
            </div>
            <div class="tab-pane fade" v-bind:id="'tab_31_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
                <UnsubscribeList></UnsubscribeList>
            </div>
         </div>
    </div>
</template>
<script>
import DashbordConfig from '../components/DashbordConfig.vue';
import ColorCodesConfig from '../components/ColorCodesConfig.vue';
import UnsubscribeList from '../components/UnsubscribeList.vue';
import SubscribersList from '../components/SubscribersList.vue';
export default {
  name: 'Segment Level Settings',
  components: {
    DashbordConfig,
    ColorCodesConfig,
    SubscribersList,
    UnsubscribeList
  },
  data: function(){
      return{
      }     
  },
  ready () {
    $(document).ready(function ($) {
    })
  },
   methods: {
    toggleSegmentCollapsedState(i) {
      this['segment'+i] = !this['segment'+i] 
    }
  }
}
</script>