<template>
<div class="row">
  <div class="col-md-12" iservice-id-prefix="'New'">
    <div class="box box-primary">
      <div class="box-header with-border">
        <i class="fa fa-envelope"></i>
        <h3 class="box-title text-right">New Mailing List</h3>
      </div>
      <div class="box-body">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label text-left">Mailing List Name</label>
              <input iservice-id="Name" type="text" class="form-control has-error">
              <!--v-if-->
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label text-left">Description</label>
              <input iservice-id="Description" type="text" class="form-control">
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="col-12 control-label text-left">Shown In My Account</label>
              <div>
                <div class="iservice-switch" iservice-id="Shown">
                  <label class="switch">
                    <input type="checkbox" iservice-switch="">
                    <i></i>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="col-12 control-label text-left">Segments <span class="data-v-tooltip" data-v-tooltip="Display this list in the subscriptions section of the customer portal for contacts in the selected segments." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);">
                      <i class="fa fa-question-circle text-info help-icon"></i>
                    </span>
                  </label>
                </div>
                <div class="form-group">
                  <div class="col-md-12">
                    <div class="checkbox col-md-3" iservice-id-prefix="'Segment'">
                      <label>
                        <input id="5" type="checkbox" class="form-check-input" value="5"> _iService </label>
                    </div>
                    <div class="checkbox col-md-3" iservice-id-prefix="'Segment'">
                      <label>
                        <input id="2" type="checkbox" class="form-check-input" value="2"> 1to1service </label>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-2">
                <button iservice-id="Create" class="btn btn-primary">Create</button>
                <button iservice-id="Cancel" class="btn btn-link">Cancel</button>
              </div>
              <div class="col-10 admin-error">
                <!--v-if-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>